import { hostContextStore } from '@guideguide/website/src/lib/state/host-context-store'
import { useStore } from '@nanostores/react'
import WebGuideGuide from './web-guideguide'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { createRef, Ref, useEffect, useState } from 'react'
import '../../styles/web-guideguide/demo-clickplate.scss'
import {
  demoStore,
  stopDemo,
} from '@guideguide/website/src/lib/demo/scripted-demo'

type TransitionableClick = {
  id: string
  coords: [number, number]
  nodeRef: Ref<HTMLDivElement>
}

const HeroGuideGuide = () => {
  const demo = useStore(demoStore)

  const [clicks, setClicks] = useState<TransitionableClick[]>([])

  useEffect(() => {
    const lookup: { [id: string]: TransitionableClick | undefined } =
      clicks.reduce((a, x) => ({ ...a, [x.id]: x }), {})

    const clickList: TransitionableClick[] = demo.lastThreeClicks.map(
      ([x, y]) => {
        const id = `${x}:${y}`
        return (
          lookup[id] ?? {
            id,
            coords: [x, y],
            nodeRef: createRef(),
          }
        )
      }
    )

    setClicks(clickList)
  }, [demo.lastThreeClicks])

  return (
    <>
      <WebGuideGuide hostContextStore={hostContextStore} />
      <TransitionGroup component={null}>
        {clicks.map(click => (
          <CSSTransition
            key={click.id}
            nodeRef={click.nodeRef}
            timeout={11000}
            className="demo-click"
            unmountOnExit
          >
            <div
              ref={click.nodeRef}
              style={{
                left: click.coords[0],
                top: click.coords[1],
              }}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      {demo.isPlaying && (
        <div className="demo-clickplate" onClick={stopDemo}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 254.95 167.35">
            <path d="M231.9 33.59c-6.27-10.86-20.15-14.58-31.01-8.31a22.684 22.684 0 0 0-8.31 8.31l-14.94 25.88c-6.27 10.86-20.15 14.58-31.01 8.31a22.684 22.684 0 0 1-8.31-8.31l-25.46-44.11c-3.19-5.52-10.26-7.41-15.78-4.22a11.54 11.54 0 0 0-4.22 4.22c-3.77 6.51-12.1 8.74-18.61 4.97a13.615 13.615 0 0 1-4.97-4.97l-5.53-9.58C60.56.25 53.49-1.64 47.97 1.55a11.54 11.54 0 0 0-4.22 4.22L0 81.54v85.81h254.95V73.48l-23.05-39.9Z" />
          </svg>
        </div>
      )}
    </>
  )
}

export default HeroGuideGuide
