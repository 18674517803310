import Core from '@guideguide/core/src/components/core'
import type { HostAPI } from '@guideguide/core/src/lib/types'
import type { HostComponents } from '@guideguide/core/src/lib/types/host-components'
import hcThemeWrapperGenerator from '@guideguide/shared/src/cep/components/hc-theme-wrapper'
import HCTabBar from '@guideguide/shared/src/cep/components/hc-tab-bar'
import HCActionBar from '@guideguide/shared/src/cep/components/hc-action-bar'
import HCQuickBar from '@guideguide/shared/src/cep/components/hc-quick-bar'
import HCGridForm from '@guideguide/shared/src/cep/components/hc-grid-form'
import HCCustomForm from '@guideguide/shared/src/cep/components/hc-custom-form'
import HCSavedForm from '@guideguide/shared/src/cep/components/hc-saved-form'
import HCModal from '@guideguide/shared/src/cep/components/hc-modal'
import HCNonModalWrapper from '@guideguide/shared/src/cep/components/hc-non-modal-wrapper'
import { useStore } from '@nanostores/react'
import type { WritableAtom } from 'nanostores'
import type { HostContext } from '@guideguide/core/src/lib/context'
import {
  Fauxtoshop,
  fauxtoshopStore,
} from '@guideguide/website/src/lib/state/fauxtoshop-store'
import { slugifyGuide } from '@guideguide/shared/src/lib/guide'
import type { Guide } from '@guideguide/shared/src/lib/guide'
import '@guideguide/shared/src/cep/theme.scss'
import {
  addDemoHandler,
  removeDemoHandler,
  demoStore,
} from '@guideguide/website/src/lib/demo/scripted-demo'
import RetainedData from '../../lib/retained-data'
import Telemetry from '../../lib/telemetry-frontend'

class CSInterface {
  static THEME_COLOR_CHANGED_EVENT = 'THEME_COLOR_CHANGED_EVENT'
  addEventListener() {}
  removeEventListener() {}
  getSystemPath() {}
  getHostEnvironment() {
    return {
      appSkinInfo: {
        panelBackgroundColor: {
          color: { red: 256 },
        },
      },
    }
  }
}

const hostComponents: HostComponents = {
  HCModal,
  HCTabBar,
  HCThemeWrapper: hcThemeWrapperGenerator(CSInterface as unknown as any),
  HCActionBar,
  HCQuickBar,
  HCGridForm,
  HCCustomForm,
  HCSavedForm,
  HCNonModalWrapper,
}
const namespace = 'com.guideguide.storage'

interface Props {
  hostContextStore: WritableAtom<HostContext>
}

if (typeof window !== 'undefined') {
  try {
    // Test for browsers with local storage disabled
    localStorage.setItem('gg-test', 'true')
    localStorage.removeItem('gg-test')
  } catch (error) {
    console.log(
      'localStorage is disabled, loading polyfill so things don’t break'
    )

    Object.defineProperty(window, 'localStorage', {
      value: {
        _data: {},
        setItem: function (id: string, val: string) {
          return (this._data[id] = String(val))
        },
        getItem: function (id: string) {
          return this._data.hasOwnProperty(id) ? this._data[id] : undefined
        },
        removeItem: function (id: string) {
          return delete this._data[id]
        },
        clear: function () {
          return (this._data = {})
        },
        key: function (i: number) {
          return Object.keys(this._data)[i] ?? null
        },
        get length() {
          return Object.keys(this._data).length
        },
      },
    })
  }
}

let hasTriedStore = false

const WebGuideGuide = ({ hostContextStore }: Props) => {
  const hostContext = useStore(hostContextStore)
  const demo = useStore(demoStore)
  const fauxtoshop = useStore(fauxtoshopStore)

  const tryGuideGuide = () => {
    // Don't send event if we already have
    if (hasTriedStore) return
    // Don't send event if the demo is running
    if (demoStore.get().isPlaying) return
    window.fathom?.trackGoal('CCP2Y0GH', 0)
    Telemetry.trackEvent('demo.interact')
    RetainedData.save({ hasTriedDemo: 1 })
    hasTriedStore = true
  }

  const hostAPI: HostAPI = {
    addGuides: async guides => {
      const hostContext = hostContextStore.get()

      if (!hostContext.document) return

      if (fauxtoshop.isBroken) {
        fauxtoshopStore.set({
          ...fauxtoshop,
          postBreakClicks: fauxtoshop.postBreakClicks + 1,
        })
        return
      }

      fauxtoshopStore.set({ ...fauxtoshop, guidesAreVisible: true })

      guides = offsetGuides(guides, fauxtoshop)

      fauxtoshopStore.set({
        ...fauxtoshop,
        guides: [...fauxtoshop.guides, ...guides],
      })
      tryGuideGuide()
    },
    clearGuides: async () => {
      const hostContext = hostContextStore.get()

      if (!hostContext.document) return
      if (fauxtoshop.isBroken) {
        fauxtoshopStore.set({
          ...fauxtoshop,
          postBreakClicks: fauxtoshop.postBreakClicks + 1,
        })
        return
      }

      const contextGuides = offsetGuides(
        hostContext.document.guides,
        fauxtoshop
      )
      const { guides: fauxtoshopGuides } = fauxtoshop

      const updatedGuideList = fauxtoshopGuides.filter(f => {
        const match = contextGuides.find(
          c => slugifyGuide(f) === slugifyGuide(c)
        )
        return !match
      })

      fauxtoshopStore.set({
        ...fauxtoshop,
        guides: updatedGuideList,
      })
      tryGuideGuide()
    },
    getDocumentContext: async () => {
      const hostContext = hostContextStore.get()
      return hostContext.document
    },
    toggleGuideVisibility: async () => {
      if (fauxtoshop.isBroken) {
        fauxtoshopStore.set({
          ...fauxtoshop,
          postBreakClicks: fauxtoshop.postBreakClicks + 1,
        })
        return
      }
      fauxtoshopStore.set({
        ...fauxtoshop,
        guidesAreVisible: !fauxtoshop.guidesAreVisible,
      })
    },
    readStorage: async () => {
      return localStorage.getItem(namespace)
    },
    writeStorage: async (data: string) => {
      localStorage.setItem(namespace, data)
    },
    handleCorruptStorage: async () => {
      localStorage.removeItem(namespace)
    },
    openURL: async (url: string) => {
      window.open(url, '_blank')
    },
    importData: async () => {
      alert('This action is not supported in the web version of GuideGuide')

      return '{}'
    },
    exportData: async () => {
      alert('This action is not supported in the web version of GuideGuide')
    },
  }

  return (
    <Core
      {...{
        hostComponents,
        hostAPI,
        hostContext,
        config: { telemetryEnabled: !demo.isPlaying },
        demoContext: {
          addDemoHandler,
          removeDemoHandler,
          isPlaying: demo.isPlaying,
        },
        onReady: () => {
          window.postMessage({ source: 'guideguide', action: 'ready' })
        },
      }}
    />
  )
}

export default WebGuideGuide

const offsetGuides = (guides: Guide[], fauxtoshop: Fauxtoshop) => {
  const offsetX = fauxtoshop.selection ? fauxtoshop.selection.x : 0
  const offsetY = fauxtoshop.selection ? fauxtoshop.selection.y : 0

  return guides.map(({ orientation, location }) => {
    return {
      orientation,
      location: location + (orientation === 'v' ? offsetX : offsetY),
    }
  })
}
